export const jobs = [
  {
    pathToImage: "/logos/tsi-logo.png",
    company: "Transworld Systems Canada",
    title: "Programmer/Analyst",
    description: 
      "Transworld Systems Canada is one of the leading debt collection companies in Canada, " +
      "with core business areas in debt collection and debt purchase.",
    link: "https://tsico.ca/en/"
  },
  {
    pathToImage: "/logos/woodbine-logo.png",
    company: "Woodbine Entertainment",
    title: "Application Developer",
    description: 
      "With almost 150 years of proud horseracing history, Woodbine Entertainment Group is the " +
      " founding pioneer and largest operator of professional horseracing in Canada.",
    link: "https://woodbine.com/",
  },
  {
    pathToImage: "/logos/bluecat-logo.png",
    company: "BlueCat Networks",
    title: "Software Engineer",
    description:
      "BlueCat helps the world’s largest organizations thrive on network complexity, from the edge to the core.",
    link: "https://bluecatnetworks.com/",
  },
  {
    pathToImage: "/logos/td-logo.png",
    company: "TD Bank",
    title: "IT Analyst",
    description:
      "TD is the sixth largest bank in North America by branches & serves approximately 22 million customers in " +
      "a number of locations in key financial centres around the globe.",
    link: "https://www.td.com/ca/en/about-td/",
  },
];

export const skills = [
  {
    title: "Object-Oriented Programming",
    description: "Java, C++",
  },
  {
    title: "Frameworks and Technologies",
    description:
      "Java Swing, Java Android, Spring Boot, Pick Multivalue, React, JSON",
  },
  {
    title: "Web Development",
    description: "HTML/CSS, Javascript",
  },
  {
    title: "Scripting",
    description: "Python, VB.Net Bash, VBA, BASIC",
  },
  {
    title: "Tools and Software",
    description: "Git, Linux, Eclipse, Visual Studio, AccuTerm, Office",
  },
  {
    title: "Soft Skills",
    description:
      "Teamwork, respect, communication, public speaking, work ethic",
  },
];
