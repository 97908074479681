import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { jobs } from "../data";
import {Routes, Route, useNavigate} from 'react-router-dom';

export default function Work() {
  return (
    <section id="work">
      <Container id="work-container">
        <Row id="work-pitch">
          <Col sm={12} md={5}>
            <h1>My Work</h1>
            <p>
              I've worked with some great companies and people throughout my career. They aren't just
              resume trophies - these are interesting projects, excellent people, and valuable experience
              I've gained over the years.
            </p>
          </Col>
          <Col className="d-flex align-items-center justify-content-center justify-content-md-end" sm={12} md={7}>
            <a className="generic-btn" id="work-resume" target="_blank" rel="noreferrer" href={window.location.origin + "/docs/itvorogov_resume.pdf"}>
            See my resume
            </a>
          </Col>
        </Row>
        <Container className="work-collection d-flex flex-wrap justify-content-sm-center justify-content-md-between">
          {jobs.map((job) => (
            <div className="work-container">
              <a href={job.link}
                 target="_blank"
                 className="job-link"
                 rel="noreferrer">
                <img className="work-image" src={window.location.origin + job.pathToImage} alt={job.imageAltText}/>
              </a>
              <p className="job-description">{job.description}</p>
            </div>
          ))}
        </Container>
      </Container>
    </section>
  );
}
