import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { HashLink } from "react-router-hash-link";
import { scrollWithOffset } from "./Helper";

import useWindowDimensions from "../utility";

export default function HeaderNavbar() {

  const {height, width} = useWindowDimensions();

  return (
    <>
      <Navbar id="navbar-main" expand="md">
        <Container id="navbar-container">
          <Navbar.Brand id="navbar-logo">
            <HashLink to="#about" scroll={el => scrollWithOffset(el)} className="navbar-link">
              <img
                id="navbar-logo"
                src={window.location.origin + "/logos/site-logo.png"}
                width="120px"
                alt="Igor Tvorogov's Developer Brand Logo"
              />
            </HashLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <HashLink to="#about" scroll={el => scrollWithOffset(el)} className="navbar-link">About</HashLink>
              <HashLink to="#work" scroll={el => scrollWithOffset(el)} className="navbar-link">Work</HashLink>
              <a href="https://github.com/iTvorogovDev" target="_blank" rel="noreferrer" className="navbar-link">
                GitHub
              </a>
              <a href={window.location.origin + "/docs/itvorogov_resume.pdf"} className="navbar-link" target="_blank" rel="noopener noreferrer">
                Resume
              </a>
            </Nav>
            <Nav id="navbar-contact">
              <HashLink to="#contact" scroll={el => scrollWithOffset(el)} id="navbar-contact" className="navbar-link">Contact me</HashLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

