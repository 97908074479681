import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HashLink } from "react-router-hash-link";
import { scrollWithOffset } from "./Helper";

export default function About(props) {

  return (
    <section id="about">
      <Container id="about-container">
        <Row>
          <Col id="about-text" sm={12} md={5}>
            <h1>About Me</h1>
            <p>
              Toronto-based generalist Software Engineer with a focus on Full Stack development.
              University of Waterloo graduate. Flexible and analytical with a problem-solving mindset.
              In my spare time, I like to exercise and write electronic music.
            </p>
            <p>
              I'm always on the lookout for exciting projects to work on and I would love to help you build yours!
            </p>
            <p>
              Interested in working with me?
            </p>
            <div id="about-btn-container">
              <HashLink to="#contact" scroll={el => scrollWithOffset(el)} className="generic-btn" id="about-contact-me">
                Contact me
              </HashLink>
              <HashLink to="#work" scroll={el => scrollWithOffset(el)} className="generic-btn generic-btn-alt-color">
                Learn more
              </HashLink>
            </div>
          </Col>
          <Col className="d-flex justify-content-md-end justify-content-sm-center" sm={12} md={7}>
              <img id="about-photo" src={window.location.origin + "/logos/about-photo.jpg"}
                height="300px" alt="Igor Tvorogov's portrait photograph"/>
          </Col>
        </Row>
      </Container>
      
    </section>
  );

}
