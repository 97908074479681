import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Contact() {
    return (
        <section id="contact">
            <Container>
            <Row>
                <Col id="contact-pitch" sm={12} md={12} lg={6}>
                    <h1 className="section-header">Contact me</h1>
                    <p>
                        Are you looking to build your next excellent project? Reach out through
                        one of the links below or fill out this contact form. Let's talk!
                    </p>
                    <div className="d-flex justify-content-sm-center justify-content-lg-start" id="contact-btn-container">
                        <a  className="generic-btn" id="contact-send-email" href="mailto:igortvorogov@gmail.com"
                            target="_blank" rel="noopener noreferrer">
                            Send me an email
                        </a>
                        <a  className="generic-btn generic-btn-alt-color" id="contact-linkedin" 
                            href="https://www.linkedin.com/in/itvorogov/"
                            target="_blank" rel="noopener noreferrer">
                            Connect on LinkedIn
                        </a>
                    </div>
                </Col>
                <Col id="contact-container" className="d-flex justify-content-center" sm={12} md={12} lg={6}>
                    <form name="contact-form" id="contact-form-container" method="post">
                        <input type="hidden" name="form-name" value="contact-form" />
                        <div className="contact-field">
                            <label className="generic-label">
                                Your name
                            </label>
                            <input
                                className="generic-input"
                                type="text"
                                id="contact-form-name"
                                name="contact-form-name"
                                maxlength="60"
                                required
                                />
                        </div>
                        <div className="contact-field">
                            <label className="generic-label">
                                Your email
                            </label>
                            <input
                                className="generic-input"
                                type="email"
                                id="contact-form-email"
                                name="contact-form-email"
                                maxlength="60"
                                required
                            />
                        </div>
                        <div className="contact-field">
                            <label className="generic-label">
                                Your message
                            </label>
                            <textarea
                                className="generic-input generic-textfield"
                                id="contact-form-message"
                                name="contact-form-message"
                                maxlength="500"
                                required
                            />
                        </div>
                        <div>
                        <button
                            type="submit"
                            className="generic-btn"
                            id="contact-submit-btn">
                            Submit  
                        </button>
                        </div>
                    </form>
                </Col>
            </Row>
            </Container>
        </section>
    );
}
