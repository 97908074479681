import "bootstrap/dist/css/bootstrap.min.css";
import "./_include-media.scss";
import "./App.scss";

import * as React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";

import HeaderNavbar from "./components/HeaderNavbar";
import About from "./components/About";
import Work from "./components/Work";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

export default function App() {

  return (
    
    <div>
      <HeaderNavbar />
      <About />
      <Work />
      <Contact />
      <hr></hr>
      <Footer />
    </div>

  );
}
