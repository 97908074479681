import Container from "react-bootstrap/Container";

export default function Footer() {
  return (
    <section id="footer">
      <Container>
        <p id="footer-statement">© Igor Tvorogov. All Rights Reserved.</p>
      </Container>
    </section>
  )
}
